var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { height: "100%, width: 100%" },
      attrs: { id: _vm.componentId },
    },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": [_vm.componentId],
            scrollable: "",
          },
          on: { hidden: _vm.modalCancel },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  _c("h5", { staticClass: "custom-modal-title" }, [
                    _vm._v(" " + _vm._s(_vm.labelTitle) + " "),
                  ]),
                  _vm.exists
                    ? [
                        _c(
                          "div",
                          { staticClass: "history-button lock-container" },
                          [
                            _vm.isLockVisible
                              ? [
                                  _c("div", { staticClass: "ml-1 mr-1" }, [
                                    _vm._v(_vm._s(_vm.$t("lock"))),
                                  ]),
                                  _c("b-form-checkbox", {
                                    attrs: {
                                      disabled: _vm.isLockReadOnly,
                                      switch: "",
                                    },
                                    model: {
                                      value: _vm.accessPolicy.readOnly,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.accessPolicy,
                                          "readOnly",
                                          $$v
                                        )
                                      },
                                      expression: "accessPolicy.readOnly",
                                    },
                                  }),
                                ]
                              : _vm._e(),
                            _c(
                              "b-button",
                              {
                                attrs: { variant: "secondary", size: "sm" },
                                on: {
                                  click: function ($event) {
                                    _vm.state.historyShow = true
                                  },
                                },
                              },
                              [
                                _c("font-awesome-icon", {
                                  attrs: { icon: ["far", "clock-rotate-left"] },
                                }),
                                _vm._v(
                                  " " + _vm._s(_vm.$t("button.history")) + " "
                                ),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "button",
                    {
                      staticClass: "close custom-modal-close",
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v("×")]
                  ),
                ]
              },
            },
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  !_vm.isAccessDenied && (_vm.canEdit() || !_vm.exists)
                    ? [
                        _vm.state.isSubmitting
                          ? _c(
                              "b-button",
                              {
                                attrs: {
                                  size: "sm",
                                  variant: "success",
                                  disabled: "",
                                },
                              },
                              [
                                _c("b-spinner", {
                                  attrs: { small: "", type: "grow" },
                                }),
                                _vm._v(_vm._s(_vm.$t("button.saving")) + " "),
                              ],
                              1
                            )
                          : _c(
                              "b-button",
                              {
                                attrs: {
                                  disabled: _vm.disableOk,
                                  size: "sm",
                                  variant: "success",
                                },
                                on: { click: _vm.modalOk },
                              },
                              [_vm._v(_vm._s(_vm.$t("button.ok")))]
                            ),
                      ]
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _vm.isAccessDenied
            ? [
                _c("div", { staticClass: "modal-message-overlay" }, [
                  _c("span", { staticClass: "grid-overlay" }, [
                    _vm._v(
                      _vm._s(
                        _vm.restrictedRequiredField != null
                          ? _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity_with_reason",
                              [
                                _vm.$t("access_policy.label").toLowerCase(),
                                _vm.restrictedRequiredField,
                              ]
                            )
                          : _vm.$t(
                              "entity_selector.error.insufficient_permission_to_add_entity",
                              [_vm.$t("access_policy.label").toLowerCase()]
                            )
                      )
                    ),
                  ]),
                ]),
              ]
            : [
                _c(
                  "b-alert",
                  {
                    attrs: {
                      variant: "danger",
                      dismissible: "",
                      show: _vm.showError,
                    },
                    on: { dismissed: _vm.dismissAlert },
                  },
                  [
                    _c("font-awesome-icon", {
                      attrs: { icon: ["fas", "triangle-exclamation"] },
                    }),
                    _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
                    _c(
                      "ul",
                      {
                        staticClass: "mb-0",
                        attrs: { show: _vm.showErrorDetail },
                      },
                      [
                        _vm._l(_vm.alertMsgDetails, function (item, index) {
                          return [
                            _c("li", { key: index }, [_vm._v(_vm._s(item))]),
                          ]
                        }),
                      ],
                      2
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "container pl-0" },
                  [
                    _c(
                      "b-row",
                      [
                        _vm.isNameVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "8" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("access_policy.field.name"),
                                      "label-for": "name",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          directives: [
                                            {
                                              name: "validate",
                                              rawName: "v-validate",
                                              value: { required: true },
                                              expression: "{ required: true }",
                                            },
                                          ],
                                          attrs: {
                                            id: "name",
                                            type: "text",
                                            "data-vv-as": _vm.$t(
                                              "access_policy.field.name"
                                            ),
                                            "data-vv-name":
                                              "access_policy.name",
                                            "data-vv-delay": "500",
                                            readonly: _vm.isNameReadOnly,
                                            trim: "",
                                            state:
                                              _vm.fieldValidateUtil.stateValidate(
                                                _vm.isReadOnly,
                                                _vm.veeFields,
                                                _vm.errors,
                                                "access_policy.name"
                                              ),
                                          },
                                          model: {
                                            value: _vm.accessPolicy.name,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.accessPolicy,
                                                "name",
                                                $$v
                                              )
                                            },
                                            expression: "accessPolicy.name",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-form-invalid-feedback",
                                      {
                                        staticClass:
                                          "alert-danger form-field-alert",
                                        class: { "d-block": _vm.showNameError },
                                      },
                                      [
                                        _c("font-awesome-icon", {
                                          attrs: {
                                            icon: ["far", "circle-exclamation"],
                                          },
                                        }),
                                        _vm._v(
                                          "  " +
                                            _vm._s(
                                              _vm.errors.first(
                                                "access_policy.name"
                                              )
                                            ) +
                                            " "
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isIdentifierVisible
                          ? _c(
                              "b-col",
                              {
                                staticClass: "pr-0",
                                attrs: { cols: "12", md: "4" },
                              },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t("field.identifier"),
                                      "label-for": "identifier",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "identifier",
                                            type: "text",
                                            "data-vv-as":
                                              _vm.$t("field.identifier"),
                                            "data-vv-name":
                                              "accessPolicy.identifier",
                                            maxlength: _vm.maxIdentifierLength,
                                            disabled: _vm.isIdentifierReadOnly,
                                            trim: "",
                                          },
                                          model: {
                                            value: _vm.accessPolicy.identifier,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.accessPolicy,
                                                "identifier",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "accessPolicy.identifier",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isDescriptionVisible
                          ? _c(
                              "b-col",
                              { staticClass: "pr-0", attrs: { cols: "12" } },
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      label: _vm.$t(
                                        "access_policy.field.description"
                                      ),
                                      "label-for": "description",
                                    },
                                  },
                                  [
                                    _c("b-form-textarea", {
                                      attrs: {
                                        id: "description",
                                        placeholder: _vm.isDescriptionReadOnly
                                          ? ""
                                          : _vm.$t(
                                              "access_policy.placeholder.description"
                                            ),
                                        "max-rows": 6,
                                        readonly: _vm.isDescriptionReadOnly,
                                        trim: "",
                                        rows: 3,
                                      },
                                      model: {
                                        value: _vm.accessPolicy.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.accessPolicy,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression: "accessPolicy.description",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.isPermissionVisible
                  ? _c("PermissionList", {
                      attrs: {
                        height: 456,
                        permissionIds: _vm.permissionIds,
                        permissionProperties: _vm.selectedPermissions,
                        readOnly: _vm.isReadOnly,
                      },
                      on: { selected: _vm.permissionSelected },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("access_policy.update_users_title"),
            "ok-title": _vm.$t("button.yes"),
            "cancel-title": _vm.$t("button.no"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: {
            ok: _vm.confirmUpdateUserPermissions,
            cancel: _vm.accessPolicySubmit,
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.confirm")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmUpdateUserPermissionsShow,
            callback: function ($$v) {
              _vm.confirmUpdateUserPermissionsShow = $$v
            },
            expression: "confirmUpdateUserPermissionsShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("access_policy.update_users_message")) + " "
            ),
          ]),
        ]
      ),
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("title_warning"),
            "content-class": "shadow",
            "no-close-on-backdrop": "",
          },
          on: { ok: _vm.confirmRevokingCriticalPermissionOk },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var ok = ref.ok
                var cancel = ref.cancel
                return [
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "success" },
                      on: {
                        click: function ($event) {
                          return ok()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.yes")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.no")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.confirmRevokingCriticalPermissionShow,
            callback: function ($$v) {
              _vm.confirmRevokingCriticalPermissionShow = $$v
            },
            expression: "confirmRevokingCriticalPermissionShow",
          },
        },
        [
          _c("div", { staticClass: "d-block" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("statement_risk_losing_account_setting_access")) +
                " "
            ),
          ]),
        ]
      ),
      _vm.exists
        ? [
            _vm.state.historyShow
              ? _c("GenericHistoryModal", {
                  attrs: {
                    show: _vm.state.historyShow,
                    id: _vm.id,
                    entityType: "ACCESS-POLICY",
                    links: "PERMISSION",
                  },
                  on: {
                    "update:show": function ($event) {
                      return _vm.$set(_vm.state, "historyShow", $event)
                    },
                  },
                })
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }